import axios from "axios";
import {errorResponse} from "./response";
import {store} from './../persist-store';
import ReducerStateUpdate from './../reducers/state-update';
import {
  VALIDITY_DATA
} from "./../action-types/state-update";

// import { useDispatch } from 'react-redux';
// import config from './../../config/api';
// import token from './../../config/token';
const apiBaseUrl = process.env.REACT_APP_API_URL;
const headers = {
  'Content-Type': 'application/json',
  'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
};
/*
 * Check if Subscription Expired, if yes redirect to Payment page
 */
function testAction(text) {
  return {
    text
  }
}
const checkSubscription = (res_type, data) => {
  try{
    const windowPath = window.location.pathname.split('/')[1];
    const blackListPath = ['pay', 'payment', 'login', 'register', 'logout'];
    if(localStorage.getItem("token")
      && blackListPath.indexOf(windowPath) === -1
    ){
      if(res_type === 'success'){
        if(typeof data.data !== "undefined"
          && typeof data.data.validity !== "undefined"
          && data.data.validity.success === true
        ){
          //Dispatch Data redux
          store.dispatch({ type: VALIDITY_DATA,
                          payload: {
                            port: data.data.validity.port,
                            ss: data.data.validity.ss,
                            port_date: data.data.validity.port_date,
                            ss_date: data.data.validity.ss_date,
                          }});
        }
      }else{
        //Check if expired
        if(typeof data.response !== "undefined"
          && typeof data.response.data !== "undefined"
          && typeof data.response.status !== "undefined"
          && data.response.status === 401
          && typeof data.response.data.redirect !== "undefined"
          && data.response.data.redirect === "payment"
        ){
          window.location.href = `/pay`;
        }
      }
    }
  }catch(error){
    console.log(error)
    return;
  }
}
/*
 * Fetch Details by Get Method
 */
export const get_api_call = async (api, payload, notify_error=true) => {
  // console.log(payload, 'payloadpayload')
  var response = {status : 500, data: {}};
  try{
    // console.log(headers, 'headers')
    // await token.setAxiosHeaderToken();
    const data = await axios.get(`${apiBaseUrl}/${api}`,
                                  {params: payload,
                                  headers});
    checkSubscription('success', data);
    return data.data;
  }catch(error){
    checkSubscription('error', error);
      console.log(error, 'eeeeeeee')
    // return cb(response);
    if(notify_error){
      return errorResponse({error, alert_error: 1});
    }else{
      return response;
    }
  }
};
/*
 * Fetch Details by Post Method
 */
export const post_api_call = async(api, payload) => {
//   var response = {status : 500, data: {}};
  // console.log(api)
  try{
        // await token.setAxiosHeaderToken();
    // token.setAxiosHeaderToken({}, async cb_token => {
        const data = await axios.post(`${apiBaseUrl}/${api}`, payload, {headers});
        checkSubscription('success', data);
        // window.location.href = `/pay`;
        // console.log(data)
        return data.data;
    //   axios({
    //     method: 'POST',
    //     url: `${apiBaseUrl}${api}`,
    //     data: payload,
    //   }).then(res => {
    //     const { data } = res;
    //     if(typeof data.status !== "undefined" 
    //       && data.status === 200
    //     ){
    //       response.status = 200;
    //     }
    //     response.data = data;
    //     return cb(response);
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       response.data = error.response.data;
    //       response.status = error.response.status;
    //     }
    //     return cb(response);
    //   });
    // });
  }catch(error){
    checkSubscription('error', error);
    if(!(typeof payload.fetch_type !== "undefined"
      && payload.fetch_type === 'logout')
    ){
      return errorResponse({error, alert_error: 1});
    }
    console.log(error)
    // return cb(response);
    return;
  }
};
/*
 * Call Error API
 */
export const error_api_call = async(payload) => {
    try{
      await axios.post(`${apiBaseUrl}/app-error`, {app: 'website', error: payload.error}, {headers});
      return 'Api Called';
    }catch(error){
      console.log(error)
      return 'Api Called:Error';
    }
  };