const todayDateForm = (eligible_year='') => {
    try{
        const currentDate = new Date();
        var todayDate = '';
        const cYear = currentDate.getFullYear();
        const cDate = currentDate.getDate();
        const cMonth = currentDate.getMonth()+1;
        if(eligible_year){
            const day = cDate > 27 ? 1 : cDate;
            todayDate = `${cYear-eligible_year}-${cMonth < 10 ? 0 : ""}${cMonth}-${day < 10 ? 0 : ""}${day}`;
        }else{
            todayDate = `${cYear}-${cMonth < 10 ? 0 : ""}${cMonth}-${cDate < 10 ? 0 : ""}${cDate}`;
        }
        // console.log(todayDate, 'todayDate')
        return todayDate;
    }catch(e){
        // console.log(e)
        return null;
    }
}
const currentMonthYear = () => {
    try{
        const currentDate = new Date();
        const cMonth = currentDate.getMonth()+1;
        const todayDate = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}`;
        return todayDate;
    }catch(e){
        return null;
    }
}

export {
    todayDateForm,
    currentMonthYear
};