import React, {useState, useEffect } from "react";
import {LoaderMedium} from './../../components/loader';
import './../../assets/css/pages/team.css';
import {SetMetaData} from './../../helper/page-title';
import {post_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import FormDataPortfolio from "../../data/formData/portfolio";
import { CharacterCount} from "../../components/form/count";

const {
    //Recommend'
    fieldReferee,
    fieldValueReferee,
    fieldValidReferee,
  } = FormDataPortfolio;

function PortRecommendForm(){
    SetMetaData("PORT – Student Portfolio Management System", "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Forms
    const [formDataReferee, setFormDataReferee] = useState({...fieldValueReferee});
    const [masterData, setMasterData] = useState({first_name: '', last_name: '', referee_name: ''});
    const [errorData, setErrorData] = useState({Referee: []});
    const [isProcess, setIsProcess] = useState({Referee: false});
    const [isSuccess, setIsSuccess] = useState(false);
    const [refId, setRefId] = useState('');
    const [isBtnDisabled, setBtnDisabled] = useState({Referee: true});
    useEffect(() => {
        //Get Referee Details
        const fetchPortfolioData = async() => {
            try{
                //Get Reference Id
                const query = new URLSearchParams(window.location.search);
                const refId = query.get("ref_id") || "";
                if(refId){
                    const portfolioApiData = await post_api_call(`referee-check`, {ref_id: refId});
                    if(portfolioApiData.status === 200){
                        //Master Data
                        setMasterData({...portfolioApiData.data.data});
                        setRefId(refId);
                        // const fieldKeys = ['name' , 'referee_name'];
                        // const RefereeFieldData = {...formDataReferee};
                        // fieldKeys.forEach(key => {
                        //     RefereeFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
                        // });
                        setFormDataReferee({...formDataReferee, name: portfolioApiData.data.data.referee_name});
                        setPageLoader(false);
                    }
                }else{
                    alertResponseMessage({alert_type: 2, message: "Link Expired"});
                }
            }catch(e){
                console.log(e);
                alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
                error_api_call({error: `portfolio/recommend|fetchPortfolioData|${e}`});
            }
        }
        fetchPortfolioData();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            if(field === 'mobile_number'){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                    || field === 'name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }
            var fieldData = identifier_type === 'array' 
                            ? [] 
                            : identifier_type === 'object'
                                ? {}
                                : '';
            switch (identifier_key) {
                case 'Referee':
                    fieldData = {...formDataReferee};
                    break;
                default:
            }
           
            if(field_type === 'array'){
                fieldData[index][field] = value;
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'Referee':
                    setFormDataReferee({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `portfolio|recommend|updateInput|${e}`});
        }
    }
    //Add Fellow on Submit Click
    const editPortfolio = async(e, form_type='', active_form='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Referee: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'Referee':
                    const fieldformDataReferee = {...formDataReferee};
                    validationRules = {...fieldValidReferee};
                    validation = ValidationHelper.validation(fieldformDataReferee, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        const basicDataKeys = ['designation',
                                                'relationship',
                                                'duration',
                                                'quality',
                                                'observation',
                                                'academic',
                                                'strength'
                                            ];
                        if(masterData.referee_name !== fieldformDataReferee.name){
                            basicDataKeys.push('name');
                        }
                        for (var key in basicDataKeys) {
                            if (fieldformDataReferee.hasOwnProperty(basicDataKeys[key])) {
                                apiFormData.append(`referee_data[${basicDataKeys[key]}]`, fieldformDataReferee[basicDataKeys[key]]);
                            }
                        }
                        apiFormData.append(`ref_id`, refId);
                    }
                    break;
                
                default:
                    break;
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());                
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`port/update`, apiFormData);
				if(updateFellow.status === 200){
                    alertResponseMessage({alert_type: 1, message: "Thank You! Information Submitted Successfully"});
                    setIsSuccess(true);
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|recommend|editPortfolio|${e}`});
        }
    }

    return (
        <>
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Letter of Recommendation</h1>
                    </div>
                    
                    <div className="row justify-content-center team-detail m-1">
                    {pageLoading 
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                    // !isSuccess
                    // ?
                        <div className="col-md-10 col-12 col-sm-12 card p-3">
                            {!isSuccess
                            ?
                                <>
                                <p>Candidate Name: <b>{masterData.first_name} {masterData.last_name}</b></p>
                                <p>Your insights on this candidate’s abilities, character, and potential would help in showcasing growth and differentiate from the crowd. Please fill the following details: </p>
                                {/* Referee Details */}
                                <form autoComplete="off">
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered table-res">
                                        <tbody>
                                        {fieldReferee.map((referee_fr, referee_idx) => (
                                            <tr key={`referee_${referee_idx}`}>
                                                <th className="align-baseline w-250p">{referee_fr.label}{referee_fr.required && (<em>*</em>)}:</th>
                                                <td>
                                                    {referee_fr.type === 'textarea'
                                                        ?
                                                        <textarea className="form-control" name={referee_fr.name} value={formDataReferee[referee_fr.name]} onChange={(e) => updateInput('Referee', 'object', 'input', referee_fr.name, e.target.value)} rows={3}/>
                                                        :
                                                        <input className="form-control" name={referee_fr.name} value={formDataReferee[referee_fr.name]} onChange={(e) => updateInput('Referee', 'object', 'input', referee_fr.name, e.target.value)}/>
                                                    }
                                                    {referee_fr.count && (
                                                        <CharacterCount
                                                            max={referee_fr.count}
                                                            string={formDataReferee[referee_fr.name]}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    {/* Error */}
                                    <div className="col-md-12 col-12 col-sm-12">
                                    {errorData['Referee'].length > 0 && (
                                        <div className="alert alert-danger" role="alert">
                                            <p>Please enter/modify following Data:</p>
                                            <ol>
                                            {errorData['Referee'].map((error, error_index) => (
                                                <li key={`error_${error_index}`}>{error}</li>
                                            ))}
                                            </ol>
                                        </div>
                                    )}
                                    </div>
                                    <p className="text-center mb-2">
                                    {!isProcess['Referee']
                                    ?
                                        isBtnDisabled['Referee']
                                        ?
                                            <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                        :
                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Referee')}>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                    }
                                    </p>
                                </form>
                                </>
                            :
                                <div className="col-md-12 col-12 col-sm-12">
                                    <div className="alert alert-success text-center mb-0" role="alert">
                                        <strong className="fs-1">Thank You!</strong>
                                        <p className="mt-2 fs-5">Thank you from IFSPD Europe for taking out time to fill this letter of recommendation. We appreciate your efforts. For more information you may visit <a href="https://ifspd.eu/port">ifpsd.eu/port</a>.</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </div>
            </section>
        </main>
     </>
    )    
}

export default PortRecommendForm;