import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// import Accordion from 'react-bootstrap/Accordion';
import Select  from 'react-select';
import { RSstandardStyle } from '../../../config/react-select';
import { FormUpload } from '../../form/upload';
import { currentMonthYear } from '../../form/date';
import { CharacterCount } from "../../form/count";
import { isLinkImg } from '../../../helper/custom-function';
// import { Fancybox as NativeFancybox } from '@fancyapps/ui';
const updateType = {
    'EduEdit' : 'EduDoc',
    'ExpEdit' : 'ExpDoc',
    'SportEdit' : 'SportDoc',
    'AwardEdit' : 'AwardDoc',
    'LifeExpEdit': 'LifeExpDoc'
};

function ModalMaster(props) {
    // const slideshowDoc = (index, docs) => {
    //     const slideDocs = docs;
    //     var displayImages = [];
    //     slideDocs.forEach(doc => {
    //         displayImages.push({
    //             src: doc.image,
    //             thumb: doc.image
    //         });
    //     });
    //     new NativeFancybox(
    //         displayImages,
    //         // Gallery options
    //         {
    //           hideScrollbar: false,
    //           startIndex: index
    //         }
    //       );
    //     //   .jumpTo(2, 0)
    // }
    const formatOptionLabel = (e, payload) => {
        if(payload.req === true){
            const content = `<b>${e[payload.key]}</b><span class="hideSpan">: ${e[payload.subkey]}</span>`;
            return (
                <span dangerouslySetInnerHTML={{ __html: content }} />
                // <span><b>{e[payload.key]}</b><span className="hideSpan">: {e[payload.subkey]}</span></span>
            )
        }
    };
    //Filter Option
    const customFilter = (option, searchText, payload) => {
        if(payload.req === true){
            if (
                option.data[payload.key].toLowerCase().includes(searchText.toLowerCase()) ||
                option.data[payload.subkey].toLowerCase().includes(searchText.toLowerCase())
                // option.data.gender.toLowerCase().includes(searchText.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    // const selectComponent = ({ children, ...prop }, key) => {
    //     console.log(prop, key, children, 'llllllllllllllllllllllllllllllllll');
    //     const { getValue, hasValue } = prop;
    //     console.log(getValue(), 'lllll')
    //     const length = getValue().id;
    //     let tmpChildren = [...children];
    //     let test = [];
    //     tmpChildren.forEach(element => {
    //         test.push({id: element.id, [key]: element[key]})
    //     });
    //     if(test.length){
    //         tmpChildren[0] = test;
    //     }
    //     if (!hasValue) {
    //       return (
    //         <components.ValueContainer {...prop}>
    //           {children}
    //         </components.ValueContainer>
    //       );
    //     }
    //     return (
    //         <>
    //       <components.ValueContainer {...prop}>
    //       {tmpChildren}
    //       </components.ValueContainer>
    //       </>
    //     );
    //   };

    return (
      <> 
        <Modal show={props.isShow||false} onHide={(e) => props.toggleModal(e, false)} size="lg" className="mod-custom team-detail" backdrop="static">
            {props.isProcess
                ?
                    <Modal.Header>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                :
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
            }
            <Modal.Body>
                {/* <Accordion>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Education/Diplomas Details</Accordion.Header>
                        <Accordion.Body> */}
                        <Form>
                            {(props.modKey === 'LifeExpNew' || props.modKey === 'LifeExpEdit') && (
                                <p>Capture moments, no matter how small, reflect on their impact, and how you'll apply the lessons. Updating this section at least once a week will track your growth. These experiences, both big and small, provide valuable leverage in college applications and interviews, as committees value rich personal insights.</p>
                            )}

                            {(props.modKey === 'SocialNew' || props.modKey === 'SocialEdit') && (
                                <p>Social Start-Up is an IFSPD Europe initiative, to enable the youth gain valuable starting-up experience and make a social change, complying to the UN-SDGs using an easy 6-steps process. You can learn more about social start-ups at <a href="ifspd.eu/start">ifspd.eu/start</a>.</p>
                            )}
                            {props.section.type !== 'doc_view'
                                ?
                                <>
                                    {(props.section 
                                        && props.section.form
                                    ) && (
                                        <>
                                        <fieldset>
                                        <legend>Details</legend>
                                            <div className="row">
                                            {props.fields.map((field, field_index) => (
                                                <Col xs={12} md={field.colMd} key={`field_${field_index}`}>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label htmlFor={field.name}>
                                                            <b>{field.label}</b>
                                                            {field.required && (<em className="required">*</em>)}
                                                            {field.tagline && <span className="fw-light">:&nbsp;{field.tagline}</span>}
                                                        </Form.Label>
                                                        {/* Input */}
                                                        {(field.type === "number" 
                                                            || field.type === "text" 
                                                            || field.type === "email") 
                                                            ?
                                                                <>
                                                                <input
                                                                    className="form-control"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    // type="text"
                                                                    type={field.type === "number" ? "text" : field.type} 
                                                                    value={props.data[field.name]}
                                                                    onChange={(e) => props.updateInput(props.formType, 'object', 'input', field.name, e.target.value)}
                                                                    placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                    readOnly={props.isProcess}
                                                                />
                                                                {field.count && (
                                                                    <CharacterCount
                                                                        max={field.count}
                                                                        string={props.data[field.name]}
                                                                    />
                                                                )}
                                                                </>
                                                            :
                                                                // Textarea
                                                                field.type === "textarea"
                                                                ?
                                                                    <>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id={field.name}
                                                                        name={field.name}
                                                                        rows={field.rows}
                                                                        value={props.data[field.name]}
                                                                        onChange={(e) => props.updateInput(props.formType, 'object', 'textarea', field.name, e.target.value)}
                                                                        placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                        readOnly={props.isProcess}
                                                                    />
                                                                    {field.count && (
                                                                        <CharacterCount
                                                                            max={field.count}
                                                                            string={props.data[field.name]}
                                                                        />
                                                                    )}
                                                                    </>
                                                                :
                                                                    // Select-Single-Start/End Year
                                                                    field.type === "year"
                                                                    ?       
                                                                        // <div className="d-flex">
                                                                            <div className="w-140p">
                                                                                <Select
                                                                                    id={field.name}
                                                                                    options={props.yearData} 
                                                                                    defaultValue={props.data[field.name]}
                                                                                    value={props.data[field.name]}
                                                                                    // label={"Select Year"}
                                                                                    placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. ${field.placeholderData[0]}`: ''}
                                                                                    onChange={(value) => props.updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                                    getOptionLabel ={(option) => option.year}
                                                                                    getOptionValue ={(option) => option.id}
                                                                                    menuPlacement="auto"
                                                                                    menuPosition={'fixed'}
                                                                                    styles={RSstandardStyle}
                                                                                />
                                                                            </div>
                                                                        :
                                                                            field.type === "year_present"
                                                                            ?
                                                                            <div>
                                                                                {!props.data.is_present && (
                                                                                    <div className="w-140p">
                                                                                        <Select
                                                                                            id={field.name}
                                                                                            options={props.yearData} 
                                                                                            defaultValue={props.data[field.name]}
                                                                                            value={props.data[field.name]}
                                                                                            // label={"Select Year"}
                                                                                            placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. ${field.placeholderData[0]}`: ''}
                                                                                            onChange={(value) => props.updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                                            getOptionLabel ={(option) => option.year}
                                                                                            getOptionValue ={(option) => option.id}
                                                                                            menuPlacement="auto"
                                                                                            menuPosition={'fixed'}
                                                                                            styles={RSstandardStyle}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                <input className="form-check-input" type="checkbox" id={`${field.name}_present`} value={props.data.is_present} checked={props.data.is_present} onChange={(e) => props.updateInput(props.formType, 'object', 'checkbox', 'is_present', e.target.checked)} />
                                                                                <label className="form-check-label" htmlFor={`${field.name}_present`}>&nbsp;Present</label>
                                                                            </div>
                                                                            : field.type === "month_present"
                                                                                ?
                                                                                    <div>
                                                                                        {!props.data.is_present && (
                                                                                            <>
                                                                                            <input type="month" className="form-control w-150p p-1" name={field.name} value={props.data[field.name]} onChange={(e) => props.updateInput(props.formType, 'object','input', field.name, e.target.value)} max={currentMonthYear()} />
                                                                                            </>
                                                                                        )}
                                                                                        <input className="form-check-input" type="checkbox" value="" id={`${field.name}_present`} checked={props.data.is_present} onChange={(e) => props.updateInput(props.formType, 'object','checkbox', 'is_present', e.target.checked)}/>
                                                                                        <label className="form-check-label" htmlFor={`${field.name}_present`}>&nbsp;Present</label>
                                                                                    </div>
                                                                                // :  field.type === "year"
                                                                                //     ?
                                                                                //         <div className="w-140p">
                                                                                //         <Select
                                                                                //             id={field.name}
                                                                                //             options={props.yearData} 
                                                                                //             defaultValue={props.data.year}
                                                                                //             value={props.data.year}
                                                                                //             // label={"Select Year"}
                                                                                //             placeholder={`Eg. 1: ${field.placeholderData[0]}`}
                                                                                //             onChange={(value) => props.updateInput(props.formType, 'object', 'select-react', 'year', value)}
                                                                                //             getOptionLabel ={(option) => option.year}
                                                                                //             getOptionValue ={(option) => option.id}
                                                                                //             menuPlacement="auto"
                                                                                //             menuPosition={'fixed'}
                                                                                //             styles={RSstandardStyle}
                                                                                //         />
                                                                                //         </div>
                                                                                    :   field.type === "month"
                                                                                        ?
                                                                                        <div>
                                                                                            <input type="month" className="form-control w-150p p-1" name={field.name} value={props.data[field.name]} onChange={(e) => props.updateInput(props.formType, 'object','input', field.name, e.target.value)} max={currentMonthYear()} min="1922-01"/>
                                                                                            
                                                                                        </div>
                                                                                        :
                                                                                            field.type === "multi-select"
                                                                                            ?
                                                                                            <div className="w-100">
                                                                                                <Select
                                                                                                    closeMenuOnSelect={false}
                                                                                                    id={field.name}
                                                                                                    options={props.selectOptions[field.name]?.data} 
                                                                                                    defaultValue={props.data[field.name]}
                                                                                                    value={props.data[field.name]}
                                                                                                    placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. 1: ${field.placeholderData[0]}`: ''}
                                                                                                    onChange={(value) => props.updateInput(props.formType, 'object', 'select-multi', field.name, value)}
                                                                                                    getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                                                    getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                                                    // menuPlacement="auto"
                                                                                                    menuPosition={'fixed'}
                                                                                                    styles={RSstandardStyle}
                                                                                                    // isDisabled ={true}
                                                                                                    formatOptionLabel={(e) => formatOptionLabel(e, props.selectOptions[field.name].format)}
                                                                                                    classNamePrefix="reactCSel"
                                                                                                    isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                                                    isSearchable={true}
                                                                                                    filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                                                    // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                                                    // isOptionDisabled={() => props.data[field.name]?.length > props.selectOptions[field.name]?.max}
                                                                                                    // selectionLimit={2}
                                                                                                    isMulti
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                                field.type === "select"
                                                                                                ?
                                                                                                <div className="w-100">
                                                                                                    <Select
                                                                                                        // closeMenuOnSelect={false}
                                                                                                        id={field.name}
                                                                                                        options={props.selectOptions[field.name]?.data} 
                                                                                                        defaultValue={props.data[field.name]}
                                                                                                        value={props.data[field.name]}
                                                                                                        placeholder={field.placeholderData && field.placeholderData.length > 1 ? `Eg. 1: ${field.placeholderData[0]}`: field.placeholderData.length > 0
                                                                                                        ? `${field.placeholderData[0]}` : ''}
                                                                                                        onChange={(value) => props.updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                                                        getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                                                        getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                                                        // menuPlacement="auto"
                                                                                                        menuPosition={'fixed'}
                                                                                                        styles={RSstandardStyle}
                                                                                                        // isDisabled ={true}
                                                                                                        // formatOptionLabel={(e) => formatOptionLabel(e, props.selectOptions[field.name].format)}
                                                                                                        // classNamePrefix="reactCSel"
                                                                                                        // isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                                                        // isSearchable={true}
                                                                                                        // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                                                        // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                                                        // isOptionDisabled={() => props.data[field.name]?.length > props.selectOptions[field.name]?.max}
                                                                                                        // selectionLimit={2}
                                                                                                        // isMulti
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                <></>
                                                        }
                                                        {(field.placeholderData.length > 1)
                                                            ?
                                                            <div className="fs-12p fst-italic mt-1 text-black-50">
                                                                {field.placeholderData.map((p_data, p_index) => (
                                                                    <span key={`place_hold${p_index}`}>
                                                                    {p_index !== 0 && (
                                                                        <span>
                                                                            <b>Eg. {p_index+1}</b>: {p_data} {p_index+1 !== field.placeholderData.length && (', ')}
                                                                        </span>
                                                                    )}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                            : (field.placeholderData.length === 1
                                                                && (field.type === "year_to_from" 
                                                                    || field.type === "month_year_from"
                                                                    || field.type === "month"))
                                                                ?
                                                                <div className="fs-12p fst-italic mt-1 text-black-50">
                                                                    <span><b>Eg.</b>: {field.placeholderData[0]}</span>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </Form.Group>
                                                </Col>
                                            ))}
                                            </div>
                                        </fieldset>
                                        {props.section.type !== 'new' && (
                                            <>
                                            {/* Error */}
                                            <div className="col-md-12 col-12 col-sm-12">
                                            {(props.errorData && props.errorData.length > 0) && (
                                                <div className="alert alert-danger mt-4" role="alert">
                                                    {/* <p className="mb-0">{props.errorData[0]}</p> */}
                                                    <p>Please enter/modify following Data:</p>
                                                    <ol>
                                                    {props.errorData.map((error, error_index) => (
                                                        <li key={`error_${error_index}`}>{error}</li>
                                                    ))}
                                                    </ol>
                                                </div>
                                            )}
                                            </div>
                                            {props.section.type !== 'doc_view' && (
                                                <p className="text-center mb-2 mt-3">
                                                {!props.isProcess
                                                ?
                                                    props.isBtnDisabled
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => props.editPortfolio(e, 'Master', props.modKey)}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${props.userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                                </p>
                                            )}
                                            </>
                                        )}
                                        </>
                                    )}
                                    {/* Certification */}
                                    {(props.section 
                                        && props.section.cert
                                    ) && (
                                        <>
                                        {props.certLabelKey && (
                                            <Form.Label><b>{props.data[props.certLabelKey]}:</b></Form.Label>
                                        )}
                                        <fieldset className="mt-4">
                                            <legend>{props.section.doc_type === 'all' ? 'Certifications' : 'Images/Photos'}</legend>
                                            <div>
                                                {(props.modKey === 'LifeExpNew' || props.modKey === 'LifeExpEdit') && (
                                                    <p className="mb-1">Add photos and images, if you have taken any, from these life altering experiences.</p>
                                                )}
                                                <FormUpload
                                                    type = {props.section.doc_type}
                                                    images = {props.doc}
                                                    removeUploadedDoc = {(e, index) => props.removeUploadedDoc(e, index)}
                                                    previewDoc = {(e, type) => props.previewDoc(e, props.section.type === 'new' ? 'Master' : 'UpdateDoc', type)}
                                                />
                                            </div>
                                        </fieldset>
                                        {props.section.type === 'edit' && (
                                            <>
                                            <div className="col-md-12 col-12 col-sm-12">
                                                {(props.errorDataUp && props.errorDataUp.length > 0) && (
                                                    <div className="alert alert-danger mt-4" role="alert">
                                                        {/* <p className="mb-0">{props.errorDataUp[0]}</p> */}
                                                        <p>Please enter/modify following Data:</p>
                                                        <ol>
                                                        {props.errorDataUp.map((error, error_index) => (
                                                            <li key={`error_${error_index}`}>{error}</li>
                                                        ))}
                                                        </ol>
                                                    </div>
                                                )}
                                            </div>
                                            <p className="text-center mb-2 mt-3">
                                            {!props.isProcessUp
                                            ?
                                                props.isBtnDisabledUp
                                                ?
                                                    <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => props.editPortfolio(e, 'UpdateDoc', updateType[props.modKey])}>Submit</button>
                                            :
                                                <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                            }
                                        
                                            <a href={`/port/${props.userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                            </>
                                        )}
                                        </>
                                    )}
                                </>
                                :
                                <>
                                    {(props.section 
                                        && props.section.cert
                                    ) && (
                                        <>
                                        {props.certLabelKey && (
                                            <Form.Label><b>{props.data[props.certLabelKey]}:</b></Form.Label>
                                        )}
                                        <fieldset className="mt-4">
                                            <legend>{props.section.doc_type === 'all' ? 'Certifications' : 'Images/Photos'}</legend>
                                            <div>
                                            <div className="img-edit-container">
                                                    {props.data.doc_data.map((doc_d, doc_indx) => (
                                                        <div className="card mb-4" key={`img-${doc_indx}`}>
                                                            <a href={`${props.data.doc_url}${doc_d.name}`} download className="delete bg-color-blue">
                                                                <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                            </a>
                                                            {isLinkImg(doc_d.name) ?
                                                                <img src={`${props.data.doc_url}${doc_d.name}`} alt="Certificate"/>
                                                            : 
                                                                <object data={`${props.data.doc_url}${doc_d.name}`} type="application/pdf">
                                                                    <p>Your web browser doesn't have a PDF plugin.
                                                                    Preview Unavailable.</p>
                                                                </object>

                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </fieldset>
                                        </>
                                    )}
                                </>
                            }
                        </Form>
                        {/* Error */}
                        {props.section.type === 'new' && (
                            <>
                            <div className="col-md-12 col-12 col-sm-12">
                                {(props.errorData && props.errorData.length > 0) && (
                                    <div className="alert alert-danger mt-4" role="alert">
                                        {/* <p className="mb-0">{props.errorData[0]}</p> */}
                                        <p>Please enter/modify following Data:</p>
                                        <ol>
                                        {props.errorData.map((error, error_index) => (
                                            <li key={`error_${error_index}`}>{error}</li>
                                        ))}
                                        </ol>
                                    </div>
                                )}
                            </div>
                            {props.section.type !== 'doc_view' && (
                                <p className="text-center mb-2 mt-3">
                                {!props.isProcess
                                ?
                                    props.isBtnDisabled
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => props.editPortfolio(e,  'Master', props.modKey)}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                            
                                <a href={`/port/${props.userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                </p>
                            )}
                            </>
                        )}
                        {/* </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
                
                {/* <Accordion className="pt-3">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Certificates</Accordion.Header>
                        <Accordion.Body>
                            
                    
                            <div className="col-md-12 col-12 col-sm-12">
                                {(props.errorData && props.errorData.length > 0) && (
                                    <div className="alert alert-danger mt-4" role="alert">
                                        <p className="mb-0">{props.errorData[0]}</p>
                                        <p>Please enter/modify following Data:</p>
                                        <ol>
                                        {props.errorData.map((error, error_index) => (
                                            <li key={`error_${error_index}`}>{error}</li>
                                        ))}
                                        </ol>
                                    </div>
                                )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <button className="btn btn-secondary" disabled={props.isProcess} onClick={(e) => props.toggleModal(e, false)}>Close</button>
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalMaster;