import React from 'react';

const FormUpload = (props) => {
    
    return (
        <>
            <p className="mb-0">Please Note:</p>
            {props.type === 'image'
                ?
                <ul>
                    <li>Maximum 2 Images/Photos Upload Allowed</li>
                    <li>Images/Photos allowed size is 1MB and it must be an image</li>
                    <li><a href="https://www.iloveimg.com/compress-image" target="_blank" rel="noreferrer">Click here to <b>Compress Images</b></a></li>
                </ul>
                :
                <ul>
                    <li>Maximum 2 Certificates Upload Allowed</li>
                    <li>Certificates allowed size is 1MB and it must be an image/pdf</li>
                    <li><a href="https://www.iloveimg.com/compress-image" target="_blank" rel="noreferrer">Click here to <b>Compress Images</b></a>&nbsp;/&nbsp;
                    <a href="https://www.ilovepdf.com/compress_pdf" target="_blank" rel="noreferrer">Click here to <b>Compress pdf</b></a></li>
                </ul>
            }
            <div className="img-edit-container">
                {props.images.map((img_d, img_indx) => (
                    <div className="card mb-4" key={`img-${img_indx}`}>
                        <button type="submit" className="delete" onClick={(e) => props.removeUploadedDoc(e, img_indx)}>
                            <span className="fw-bold">&times;</span>
                        </button>
                        {/* <img src={img_d.src} alt="SocialImage"/> */}
                        {img_d.is_image ?
                            <img src={img_d.src} alt="Certificate"/>
                        : 
                            // <embed src={img_d.src}/>
                            <object data={img_d.src} type="application/pdf">
                                <p>Your web browser doesn't have a PDF plugin.
                                {/* Instead you can <a href={img_d.src} target="_blank" rel="noreferrer">click here to */}
                                Preview Unavailable.</p>
                            </object>
                            // <iframe src={`https://docs.google.com/viewer?url=${img_d.src}&embedded=true`} frameborder="0"></iframe>

                        }
                    </div>
                ))}
            </div>
            {props.images.length < process.env.REACT_APP_UPLOAD_FILE_QTY && (
            <div className="upload-img text-center m-3 d-block">
                <div className="btn btn-light btn-outline-primary">
                    {props.type === 'image'
                    ?
                    <>
                        <span>Browse{props.images.length > 0 ? " More" :  ""} Images</span>
                        <input type="file" className="logo-input" multiple name="profile_pic" onChange={(e) => props.previewDoc(e, props.type)} accept="image/x-png,image/jpg,image/jpeg" />
                    </>
                    :
                    <>
                        <span>Browse{props.images.length > 0 ? " More" :  ""} Certificates</span>
                        <input type="file" className="logo-input" multiple name="profile_pic" onChange={(e) => props.previewDoc(e, props.type)} accept="image/x-png,image/jpg,image/jpeg,application/pdf" />
                    </>
                    }
                </div>
            </div>
            )}
        </>
    );
  }

export {
    FormUpload
};