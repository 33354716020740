import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function WrapTooltip(props) {

    return (
      <>
      <OverlayTrigger
            placement="bottom"
            delay={{ show: 0, hide: 0 }}
            overlay={<Tooltip>{props.title}</Tooltip>}
        >  
            {props.children}
        </OverlayTrigger>
      </>
    );
  }
  
  export default WrapTooltip;