const commonStyle = {
    menu: (base) => ({
        ...base,
        // width: "200px",
        // minWidth: "100%"
    }),
    // control: (provided, state) => ({
    //     ...provided,
    //     color: "red",
    // }),
    option: (provided, state) => ({
        ...provided,
        // color: state.isDisabled ? "#e9ecef" : "black",
        opacity: state.isDisabled ? 0.5 : 1,
    }),
    singleValue: provided => ({
        ...provided,
        color: 'black',
        fontSize: '1rem'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontSize: 14,
            color: '#919191',
            // fontWeight: 500
        }
    }
};

const RSstandardStyle = {...commonStyle};
const RSInputStyle = {...commonStyle,
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            // borderBottomRightRadius: 12,
                            // borderTopRightRadius: 12,
                            // borderBottomLeftRadius: 0,
                            borderRadius: '0 12px 12px 0',
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHeight: 1.5,
                            height: 24
                        }),
                        placeholder: defaultStyles => {
                            return {
                                ...defaultStyles,
                                color: '#6c757d',
                                fontSize: 16,
                                // color: '#919191'
                            };
                        },
                    };
const RSRegisterStyle = {
    ...RSInputStyle,
    control: (baseStyles, state) => ({
        ...baseStyles,
        // borderRadius: 0,
        // fontSize: '1rem',
        // fontWeight: 400,
        // lineHeight: 1.5,
        // height: 24
        height: 'calc(2.7rem + 2px)',
        // lineHeight: 1,
        borderColor: '#cccccc',
        boxShadow: 'none',
        borderRadius: '0 12px 12px 0',
        // marginTop: (state.hasValue || state.selectProps.inputValue) && '1rem'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        position: "absolute",
        top: (state.hasValue || state.selectProps.inputValue) ? '2.8px' : 'auto',
        // backgroundColor:
        //   state.hasValue || state.selectProps.inputValue
        //     ? "white"
        //     : "transparent",
        transition: "top 0.1s, font-size 0.1s",
        // fontSize: 18,
        fontSize: (state.hasValue || state.selectProps.inputValue) ? 13 : 16,
        color: '#919191',
        opacity: (state.hasValue || state.selectProps.inputValue) && .65,
        // top: -2px;
        fontWeight: 400,
        // whiteSpace: 'nowrap'
        lineHeight: 1
        // transform: 'scale(.85) translateY(-.5rem) translateX(.15rem)',
        // marginLeft: -5
    }),
    singleValue:(provided, state) => ({
        ...provided,
        position: (state.hasValue || state.selectProps.inputValue) && 'absolute',
        top: (state.hasValue || state.selectProps.inputValue) && 15,
    }),
    input:(provided, state) => ({
        ...provided,
        // position: (state.hasValue || state.selectProps.inputValue) && 'absolute',
        // top: (state.hasValue || state.selectProps.inputValue) && 13,
        paddingTop: (state.hasValue || state.selectProps.inputValue) && 12
    }),
};
const RSNoRadiusStyle = {
    ...RSRegisterStyle,
    control: (baseStyles, state) => ({
        ...baseStyles,
        height: 'calc(2.7rem + 2px)',
        // lineHeight: 1,
        borderColor: '#cccccc',
        boxShadow: 'none',
        borderRadius: 0,
        // fontSize: '1rem',
        // fontWeight: 400,
        // lineHeight: 1.5,
        // height: 24
    }),
};
const RSRegErrStyle = {
    ...RSRegisterStyle,
    control: (baseStyles, state) => ({
        ...baseStyles,
        height: 'calc(2.7rem + 2px)',
        // lineHeight: 1,
        borderColor: 'red',
        borderRadius: '0 12px 12px 0',
        // boxShadow: 'none',
        // borderRadius: 0,
        // fontSize: '1rem',
        // fontWeight: 400,
        // lineHeight: 1.5,
        // height: 24
    }),
};

const RSNoRadiusErrStyle = {
    ...RSRegisterStyle,
    control: (baseStyles, state) => ({
        ...baseStyles,
        height: 'calc(2.7rem + 2px)',
        // lineHeight: 1,
        borderColor: 'red',
        boxShadow: 'none',
        borderRadius: 0,
        // fontSize: '1rem',
        // fontWeight: 400,
        // lineHeight: 1.5,
        // height: 24
    }),
};

export {RSstandardStyle,
        RSInputStyle,
        RSNoRadiusStyle,
        RSRegisterStyle,
        RSRegErrStyle,
        RSNoRadiusErrStyle
    };