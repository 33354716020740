import axios from "axios";
import moment from 'moment';
import {
  USER_INFO,
  // TOKEN
} from "../action-types/auth";
import {errorResponse} from "./response";
const apiUrl = process.env.REACT_APP_API_URL;
/*
 * Use to Login/Register to App/Web
 */
export const login_register = (api, payload, cb) => async dispatch => {
  var response = {success_status : -1,
                  status: 500,
                  msg: api === 'register'
                                ? 'Error Registering! Please Try Again'
                                : 'Error Logging In! Please Try Again',
                  data: {}};
  try {
    const user = await axios.post(`${apiUrl}/${api}`, payload);
    const { data } = user;
    if(data.status === 200){
      //Successfully logged In
      response.success_status = 1;
      response.msg = '';
      response.data = api === 'login/email' ? data : data.data;
      response.status = data.status;
      if(api !== 'user-check'
        // && (data.data.is_verified === 1
            // || data.data.is_verified === 3)
      ){
        const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        localStorage.setItem("token", data.token);
        localStorage.setItem("token_dt", currentDateTime);
        localStorage.setItem("is_verified", data.data.is_verified);
        dispatch({type: USER_INFO,
                  payload: {first_name: data.data.first_name,
                            // is_verified: data.data.is_verified,
                            is_verified: 3,
                            last_name: data.data.last_name,
                            user_name: data.data.user_name,
                            image: data.data.image
                            // is_email_verified: data.data.is_email_verified
                          }});
      // dispatch({ type: TOKEN, payload: data.token});
      }else if(api === 'user-check'){
        response.is_mobile = data.is_mobile;
      }
    }
    return cb(response);
  } catch (error) {
    console.log('error1', error)
    // console.log('error1', error.response.data)
    // console.log('error', typeof error.response)
    // console.log('error2', typeof error.response.data)
    // console.log('error3', typeof error.response.data.message)
    if(typeof error.response !== "undefined"
      && typeof error.response.data !== "undefined"
      && typeof error.response.data.message !== "undefined"
    ){
      response.msg = error.response.data.message;
      response.status = error.response.status;
    }
    if(api !== 'user-check'){
      dispatch({ type: USER_INFO, payload: null });
      // dispatch({ type: TOKEN, payload: '' });
    }else{
      response.is_mobile = error.response.data.is_mobile;
    }
    return cb(response);
  }
};
/*
 * Use to Update is_verified state
 */
export const update_verify_state = (payload, cb) => async dispatch => {
  var response = {status: 500,
                  msg: 'Something Went wrong, Please try after some times'};
  try {
    localStorage.setItem("is_verified", payload.is_verified);
    dispatch({type: USER_INFO,
              payload: {first_name: payload.first_name,
                        is_verified: payload.is_verified,
                        // is_email_verified: payload.is_email_verified
                      }});
    return cb({status: 200});
  } catch (error) {
    errorResponse({error: response.msg, alert_error: 2});
    return cb(response);
  }
};
/*
 * Use to Logout
 */
export const logout = (payload, cb) => async dispatch => {
  var response = {status : 500};
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("token_dt");
    localStorage.removeItem("is_verified");
    dispatch({ type: USER_INFO, payload: null});
    // dispatch({ type: USER_INFO, payload: {first_name: 'User',
    //           user_name: '',
    //           is_verified: '0',
    //           image: ''
    //           // is_email_verified: '0'
    //         }});
    // dispatch({ type: USER_INFO, payload: {first_name: '',
    //           is_verified: '',
    //           is_email_verified: ''}});
    response.status = 200;
    return cb(response);
  } catch (error) {
    console.log(error)
    return cb(response);
  }
};
/*
 * Use to Reset Password
 */
export const reset_password = (api, payload, cb) => async dispatch => {
  var response = {success_status : -1,
                  status: 500,
                  msg: 'Error Re-setting Password ! Please Try Again',
                  data: {}};
  try {
    const user = await axios.post(`${apiUrl}/${api}`, payload);
    const { data } = user;
    if(data.status === 200){
      //Successfully logged In
      response.success_status = 1;
      response.msg = '';
      response.data = data.data;
      response.status = data.status;
      if(api !== 'reset-password/user-check'
        // && (data.data.is_verified === 1
            // || data.data.is_verified === 3)
      ){
        const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        localStorage.setItem("token", data.token);
        localStorage.setItem("token_dt", currentDateTime);
        localStorage.setItem("is_verified", data.data.is_verified);
        dispatch({type: USER_INFO,
                  payload: {first_name: data.data.first_name,
                            is_verified: data.data.is_verified,
                            // is_email_verified: data.data.is_email_verified
                          }});
      // dispatch({ type: TOKEN, payload: data.token});
      }
    }
    return cb(response);
  } catch (error) {
    console.log('error1', error.response.data)
    console.log('error', typeof error.response)
    console.log('error2', typeof error.response.data)
    console.log('error3', typeof error.response.data.message)
    if(typeof error.response !== "undefined"
      && typeof error.response.data !== "undefined"
      && typeof error.response.data.message !== "undefined"
    ){
      response.msg = error.response.data.message;
      response.status = error.response.status;
    }
    if(api !== 'reset-password/user-check'){
      dispatch({ type: USER_INFO, payload: null });
      // dispatch({ type: TOKEN, payload: '' });
    }
    return cb(response);
  }
};