import { store } from 'react-notifications-component';
export const errorResponse = (payload) => {
    var response = {status : 500, message: 'Something Went Wrong, Please Try After Some Time'};
    try {
      const { error, alert_error } = payload;
      if(typeof error.response !== "undefined"
          && typeof error.response.data !== "undefined"
      ){
          if(typeof error.response.data.message !== "undefined"){
              response.message = error.response.data.message;
          }
  
          if(typeof error.response.status !== "undefined"){
              response.status = error.response.status;
              // console.log(error.response.status, 'error.response.status')
              if(error.response.status === 404){
                response.message = 'Not Found';
              }else if(error.response.status === 500){
                response.message = 'Internal Error';
              }
          }
      }
      if(alert_error === 1){
          alertResponseMessage({alert_type: 2, message: response.message});
      }
      return response;
    } catch (e) {
      return response;
    }
  };
  
  export const alertResponseMessage = (payload) => {
      try {
        const { alert_type, message } = payload;
        const title = payload.title || "";
        const notifyType = {
          1: 'success',
          2: 'danger',
          4: 'info',
          3: 'warning'
        };
        //alert_type: 1=>success, 2=> error, 3=> warning
        store.addNotification({
          title,
          message,
          type: notifyType[alert_type],
          insert: "top",
          // container: "bottom-center",
          container: "center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true
          }
        });
        // alert(message);//Replace with Custom UI
      } catch (e) {
      //   return response;
      }
  };
  