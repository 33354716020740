const yesNoData = () => {
    const data =  [
        {id: 1, title: 'Yes'},
        {id: 2, title: 'No'}
    ];
    return data;
}
const yesNoOption = [
        {id: 1, title: 'Yes'},
        {id: 2, title: 'No'}
    ];
const yesNoValue = {
    // const data =  {
        1: 'Yes',
        2: 'No'
    // };
    // return data;
}
const optionDisValue = {
    // const data =  {
        1: 'Within 1km',
        2: 'Online',
        3: 'None of the above'
    // };
}
const optionAddressValue = {
    1: 'Online',
    2: 'Physical'
}
const optionSatisValue = {
    1: 'Very satisfied',
    2: 'Somewhat satisfied',
    3: 'Neither satisfied nor dissatisfied',
    4: 'Somewhat dissatisfied',
    5: 'Not at all satisfied'
}
const optionAchiveKPI = {
    1: 'Achieved the KPI',
    2: 'Fell short of the KPI',
    3: 'Exceeded the KPI'
}
const optionSatisfaction = () => {
    const data =  [
        {id: 1, title: 'Very satisfied'},
        {id: 2, title: 'Somewhat satisfied'},
        {id: 3, title: 'Neither satisfied nor dissatisfied'},
        {id: 4, title: 'Somewhat dissatisfied'},
        {id: 5, title: 'Not at all satisfied'}
    ];
    return data;
}
const optionStageData = [{id: 1, stage: 'Stage 1'},
                {id: 2, stage: 'Stage 2'},
                {id: 3, stage: 'Stage 3'},
                {id: 4, stage: 'Stage 4'},
                {id: 5, stage: 'Stage 5'},
                {id: 6, stage: 'Stage 6'}];
                
export {yesNoData,
        yesNoValue,
        optionDisValue,
        optionAddressValue,
        optionSatisValue,
        optionSatisfaction,
        optionStageData,
        optionAchiveKPI,
        yesNoOption
    };