import React, {useEffect, useState} from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const isAuth = localStorage.getItem("token") ? true : false;

const RouteAuth = () => {
    const location = useLocation();
    const [redirectUri, setRedirectUri] = useState('');
    useEffect(() => {
        const urlHandling = () => {
          try{
            // const urlQuryString = window.location.search;
            const urlQuryString = location.search;
            const urlParam = new URLSearchParams(urlQuryString);
            const urlRedirect = urlParam.get('redirect_uri');
            console.log(location.pathname, 'location.pathname')
            console.log(urlRedirect, 'urlRedirectauthhhh')
            if(urlRedirect !== '' && urlRedirect !== null){
              setRedirectUri(urlRedirect);
            }
          }catch(e){
            // error_api_call({error: `auth/register|urlHandling|${e}`});
          }
        }
        urlHandling();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return !isAuth
            ? <Outlet />
            : <Navigate to={{
                // pathname: '/port',
                pathname: (location.pathname === '/register'
                            || location.pathname === '/login'
                        ) && redirectUri ? `/${redirectUri}` : '/',
                state: {from: location.pathname}
            }} />
            // : <p>{(location.pathname === '/register'
            //                     || location.pathname === '/login'
            //                 ) && redirectUri ? `/${redirectUri}` : '/'}</p>
}

export default RouteAuth;

// import {Navigate, Route } from 'react-router-dom';

// const isAuth = localStorage.getItem("token") ? true : false;

// export default ({component: Component, ...rest}) => (
//     <Route {...rest} render={props => (
//     //   window.globalState.isAuthenticated() ? (
//     !isAuth ? (
//         <Component {...props} />
//       ) : (
//         <Navigate to={{
//           pathname: '/',
//           state: {from: props.location}
//         }} />
//       )
//     )} />
// )